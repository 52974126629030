/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKProgress from "components/MKProgress";
import MKButton from "components/MKButton";

// Coworking page component
// import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";

function ThreeItemsVertical({ head1, head2, title, description, item1, item2, item3 }) {
  return (
    <MKBox component="section" py={2}>
      <MKProgress color="dark" value={80} />
      <MKBox component="nav" position="relative" width="100%">
        <Container py={5}>
          <Grid container flexDirection="row" alignItems="center">
            <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
              {head1} {head2}
            </MKTypography>
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}></MKTypography>
            </MKBox>
            <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
              <MKButton color="default" href="/lesson/page3">
                Go Back
              </MKButton>
              <MKButton color="default" href="/lesson/page5">
                Next Page
              </MKButton>
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={3}>
            <MKTypography variant="h4" my={1} py={8}>
              {title}
            </MKTypography>
            <MKTypography variant="body1" color="text" mb={2}>
              {description}
            </MKTypography>
            <MKTypography
              component="a"
              href="#"
              variant="body2"
              color="info"
              fontWeight="regular"
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateX(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateX(6px)",
                },
              }}
            >
              {/* More about us
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon> */}
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={8} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <MKTypography variant="body1" mb={1} py={8}>
                {item1}
              </MKTypography>
              <MKTypography variant="body1" mb={1} py={0}>
                {item2}
              </MKTypography>
              <MKTypography variant="body1" mb={1} py={6}>
                {item3}
              </MKTypography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

ThreeItemsVertical.defaultProps = {
  head1: "Cyber Security Training Course Name",
  head2: "( 4 of 5 )",
  title: "Page Title",
  description:
    "Page Description goes here. Kila siku ninahitaji kula chakula vitamu. Nataka matunde na mboga na nyama mingi sana.",
  item1: "item1 info kitu kimoja kufanya",
  item2: "item2 info vitu wawili kufanya",
  item3: "item3 info vitu vitatu kufanya",
};

ThreeItemsVertical.propTypes = {
  head1: PropTypes.string,
  head2: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  item1: PropTypes.string,
  item2: PropTypes.string,
  item3: PropTypes.string,
};

export default ThreeItemsVertical;
