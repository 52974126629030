/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKProgress from "components/MKProgress";

// Material Kit 2 PRO React examples
// import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

function FeaturesThree({
  head1,
  head2,
  title,
  // description,
  item1,
  item2,
  // item3,
  // title1,
  // title2,
  // title3,
}) {
  return (
    <MKBox component="section" py={2}>
      <MKProgress color="dark" value={60} />
      <MKBox component="nav" position="relative" width="100%">
        <Container py={5}>
          <Grid container flexDirection="row" alignItems="center">
            <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
              {head1} {head2}
            </MKTypography>
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}></MKTypography>
            </MKBox>
            <MKTypography color="black" fontWeight="regular" py={0.8125} mr={2}>
              <MKButton color="default" href="/lesson/2">
                Go Back
              </MKButton>
              <MKButton color="default" href="/lesson/4">
                Next Page
              </MKButton>
            </MKTypography>
            {/* <MKButton color="default">Begin Training</MKButton> */}
          </Grid>
        </Container>
      </MKBox>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          mx="auto"
          textAlign="center"
          pb={8}
        >
          <MKTypography variant="h2" mb={1} py={4}>
            {title}{" "}
          </MKTypography>
          <br></br>
          <MKTypography variant="h4" color="text" py={2}>
            {item1}{" "}
          </MKTypography>
          <MKTypography variant="h4" color="text" py={2}>
            {item2}{" "}
          </MKTypography>
        </Grid>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              color="primary"
              icon="person"
              title={title1}
              description={item1}
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              icon="support_agent"
              title={title2}
              description={item2}
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SimpleInfoCard
              color="warning"
              icon="update"
              title={title3}
              description={item3}
              direction="center"
            />
          </Grid>
        </Grid> */}
        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}></Grid>
          <Grid item xs={12} md={6} lg={4}>
            <center>Page (1 of 5)</center>
          </Grid>
          <Grid item xs={12} md={6} lg={4}></Grid>
        </Grid> */}
        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MKButton variant="gradient" color="info" fullWidth>
              Previous Page
            </MKButton>
          </Grid>
          <Grid item xs={12} md={6} lg={4}></Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKButton variant="gradient" color="info" fullWidth>
              Next Page
            </MKButton>
          </Grid>
        </Grid> */}
      </Container>
    </MKBox>
  );
}

// Setting default props for the ExampleCard
FeaturesThree.defaultProps = {
  head1: "Cyber Security Training Course Name",
  head2: "( 3 of 5 )",
  title: "test",
  description: "test",
  title1: "item1 info",
  title2: "item2 info",
  title3: "item3 info",
  item1: "item1 info",
  item2: "item2 info",
  item3: "item3 info",
};

// Typechecking props for the ExampleCard
FeaturesThree.propTypes = {
  head1: PropTypes.string,
  head2: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  item1: PropTypes.string,
  item2: PropTypes.string,
  item3: PropTypes.string,
  title1: PropTypes.string,
  title2: PropTypes.string,
  title3: PropTypes.string,
};

export default FeaturesThree;
