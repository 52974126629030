/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Copyright 2023 A2K Solutions, Ltd
*/
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ThreeItemsVertical from "training/templates/ThreeItemsVertical";
import HeaderPage from "training/templates/HeaderPage";
import FaqList from "training/templates/FaqList";
import TwoItems from "training/templates/TwoItems";
import TwoRowInfo from "training/templates/TwoRowInfo";
// import LessonPage from "training/pages/lesson";
import FeaturesThree from "training/templates/FeaturesThree";

const LessonPage = () => {
  const SERVER_URL = "https://cyberback.a2k.solutions";
  // process.env.REACT_APP_SERVER_URL;

  const [pageData, setPage] = useState(
    <HeaderPage head1="" head2="" title="" nextpage="" button="" description="" />
  );
  const { id } = useParams();

  useEffect(() => {
    // alert(JSON.stringify(id));
    fetch(SERVER_URL + "/content/pages/", {
      method: "POST",
      body: JSON.stringify({
        contentId: 1,
        contentPage: id,
      }),
    })
      .then(
        (res) => res.json()
        //res => res.text()
      )
      .then(
        (item) => {
          switch (item.pageTemplate) {
            case "ThreeItemsHorizontal":
              setPage(
                <FeaturesThree
                  title={item.pageHeader}
                  description={item.pageInfo}
                  item1={item.item1}
                  item2={item.item2}
                  item3={item.item3}
                  title1={item.title1}
                  title2={item.title2}
                  title3={item.title3}
                  progress={(item.pageNumber / item.pageTotal) * 100}
                  nextpage={+item.pageNumber + 1}
                />
              );
              break;
            case "FaqList":
              setPage(
                <FaqList
                  title1="Complexity:"
                  info1="Passwords should include a combination of uppercase and lowercase letters, numbers, and special symbols or punctuation marks."
                  title2="Length:"
                  info2="Ideally, a password should be at least 12 characters long. However, opting for an even longer password is preferable."
                  title3="Unpredictability:"
                  info3="Password should avoid easily guessable patterns, such as sequential numbers or common phrases."
                  title4="Uniqueness:"
                  info4="A password should be distinct from other passwords you use, particularly across different accounts and platforms."
                  title5="Randomness:"
                  info5="Passwords should typically include a combination of different character types, such as uppercase letters, lowercase letters, numbers, and special characters (e.g., !@#$%&^*) rather than just birthday dates and pet names."
                  title6="Personal Information:"
                  info6="Passwords should avoid personal information, they should not include personal details such as your name, birthdate, or any easily discoverable information about you."
                  progress={(item.pageNumber / item.pageTotal) * 100}
                  nextpage={+item.pageNumber + 1}
                />
              );
              break;
            case "ThreeItemsVertical":
              setPage(
                // <ThreeItemsVertical
                //   title={item.pageHeader}
                //   description={item.pageInfo}
                //   item1={item.item1}
                //   item2={item.item2}
                //   item3={item.item3}
                // />
                <ThreeItemsVertical
                  title="The three common factors used in multi-factor authentication are:"
                  description=""
                  item1="Something you know: This factor requires knowledge that only the user should possess, such as a password, PIN, or answers to security questions."
                  item2="Something you have: This factor involves a physical object that the user possesses, such as a smartphone, a hardware token, a smart card, or a USB security key. This object generates a one-time password (OTP) or acts as a second authentication factor."
                  item3="Something you are: This factor relies on unique physical or behavioral characteristics of the user, often referred to as biometrics. Biometric factors can include fingerprints, facial recognition, voice recognition, or iris scans."
                />
              );
              break;
            case "TwoItems":
              setPage(
                <TwoItems
                  title="Multi-factor authentication (MFA)"
                  // description={pageData.pageInfo}
                  item1="Multi-factor authentication (MFA), also known as two-factor authentication (2FA) or multi-step verification, is a security mechanism that requires users to provide two or more different factors to verify their identity when accessing a system, application, or online service."
                  item2="Implementing multi-factor authentication can greatly enhance security and protect against various types of attacks, including password theft, phishing, and brute-force attacks. Many online services, banking institutions, and organizations offer MFA options to their users, through SMS verification codes, biometric technologies which is protection beyond username and password combination."
                  // item3={pageData.item3}
                  // title1={pageData.title1}
                  // title2={pageData.title2}
                  // title3={pageData.title3}
                />
              );
              break;
            case "TwoRowInfo":
              setPage(<TwoRowInfo head1="testing" />);
              break;
            default:
              setPage(<FaqList />);
          }

          //   setData(result);
          //   alert(JSON.stringify(result));
        },
        // Note: it's important to handle errors here instead of a catch() block so that we don't swallow exceptions from actual bugs in components.
        (error) => {
          alert(error);
        }
      );
  }, []);

  return pageData;
};

// const getPage = (item) => {
//   // alert(JSON.stringify(item));
//   if (item.pageTemplate == "ThreeItemsHorizontal") {
//     return (
//   <FeaturesThree
//     title={item.pageHeader}
//     description={item.pageInfo}
//     item1={item.item1}
//     item2={item.item2}
//     item3={item.item3}
//     title1={item.title1}
//     title2={item.title2}
//     title3={item.title3}
//   />
//     );
//   } else if (item.pageTemplate == "ThreeItemsVertical") {
//     return (
//       <ThreeItemsVertical
//         title={item.pageHeader}
//         description={item.pageInfo}
//         item1={item.item1}
//         item2={item.item2}
//         item3={item.item3}
//       />
//     );
//   } else if (item.pageTemplate == "FaqList") {
//     return (
//       <FaqList
//         title1="Complexity:"
//         info1="Passwords should include a combination of uppercase and lowercase letters, numbers, and special symbols or punctuation marks."
//         title2="Length:"
//         info2="Ideally, a password should be at least 12 characters long. However, opting for an even longer password is preferable."
//         title3="Unpredictability:"
//         info3="Password should avoid easily guessable patterns, such as sequential numbers or common phrases."
//         title4="Uniqueness:"
//         info4="A password should be distinct from other passwords you use, particularly across different accounts and platforms."
//         title5="Randomness:"
//         info5="Passwords should typically include a combination of different character types, such as uppercase letters, lowercase letters, numbers, and special characters (e.g., !@#$%&^*) rather than just birthday dates and pet names."
//         title6="Personal Information:"
//         info6="Passwords should avoid personal information, they should not include personal details such as your name, birthdate, or any easily discoverable information about you."
//       />
//     );
//   } else if (item.pageTemplate == "TwoItems") {
//     return (
//       <TwoItems
//         title="Multi-factor authentication (MFA)"
//         item1="Multi-factor authentication (MFA), also known as two-factor authentication (2FA) or multi-step verification, is a security mechanism that requires users to provide two or more different factors to verify their identity when accessing a system, application, or online service."
//         item2="Implementing multi-factor authentication can greatly enhance security and protect against various types of attacks, including password theft, phishing, and brute-force attacks. Many online services, banking institutions, and organizations offer MFA options to their users, through SMS verification codes, biometric technologies which is protection beyond username and password combination."
//       />
//     );
//   } else if (item.pageTemplate == "HeaderPage") {
//     return <HeaderPage />;
//   }
// };

export default LessonPage;
