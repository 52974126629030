/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect } from "react"; // react-router components
import { Routes, Route, Navigate } from "react-router-dom";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// Material Kit 2 PRO React themes
import theme from "assets/theme";
// import Presentation from "layouts/pages/presentation";
// import FeaturesOne from "layouts/sections/page-sections/featuers/components/FeaturesOne";
// import FeaturesTwo from "layouts/sections/page-sections/featuers/components/FeaturesTwo";
// import FeaturesThree from "layouts/training/pages/FeaturesThree";
import ThreeItemsVertical from "layouts/training/pages/ThreeItemsVertical";
import HeaderPage from "training/templates/HeaderPage";
import FaqList from "layouts/training/pages/FaqList";
import TwoItems from "layouts/training/pages/TwoItems";
import TwoRowInfo from "layouts/training/pages/TwoRowInfo";
import LessonPage from "training/pages/lesson";
import QuizPage from "training/pages/quiz";
// Material Kit 2 PRO React routes
// import routes from "routes";

export default function App() {
  // const { pathname } = useLocation();
  // Setting page scroll to 0 when changing the route
  // useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   // alert("test");
  // }, [pathname]);
  // const SERVER_URL = "https://cyberback.a2k.solutions";
  // const [pageData, setPage] = useState([]);

  useEffect(() => {
    // const getorganizations = {
    //   // userId: token.uid,
    //   // organazationUid: token.org_guid,
    //   contentId: 1,
    //   contentPage: 1,
    // };
    // fetch(SERVER_URL + "/content/pages/", {
    //   method: "POST",
    //   body: JSON.stringify(getorganizations),
    // })
    //   .then((res) => res.json())
    //   .then(
    //     (result) => {
    //       // setPage(result);
    //       // alert(JSON.stringify(result));
    //       // alert(JSON.stringify(result[0]));
    //       // alert(JSON.stringify(pageData[0]));
    //       // alert(JSON.stringify(pageData[1].pageTemplate));
    //     },
    //     // Note: it's important to handle errors here instead of a catch() block so that we don't swallow exceptions from actual bugs in components.
    //     (error) => {
    //       alert(error);
    //     }
    //   );
  }, []);

  // const getPage = (item) => {
  //   // alert(JSON.stringify(item));
  //   if (item.pageTemplate == "ThreeItemsHorizontal") {
  //     return (
  //       <FeaturesThree
  //         title={item.pageHeader}
  //         description={item.pageInfo}
  //         item1={item.item1}
  //         item2={item.item2}
  //         item3={item.item3}
  //         title1={item.title1}
  //         title2={item.title2}
  //         title3={item.title3}
  //       />
  //     );
  //   } else if (item.pageTemplate == "ThreeItemsVertical") {
  //     return (
  //       <ThreeItemsVertical
  //         title={item.pageHeader}
  //         description={item.pageInfo}
  //         item1={item.item1}
  //         item2={item.item2}
  //         item3={item.item3}
  //       />
  //     );
  //   } else if (item.pageTemplate == "FaqList") {
  //     return (
  //       <FaqList
  //         title1="Complexity:"
  //         info1="Passwords should include a combination of uppercase and lowercase letters, numbers, and special symbols or punctuation marks."
  //         title2="Length:"
  //         info2="Ideally, a password should be at least 12 characters long. However, opting for an even longer password is preferable."
  //         title3="Unpredictability:"
  //         info3="Password should avoid easily guessable patterns, such as sequential numbers or common phrases."
  //         title4="Uniqueness:"
  //         info4="A password should be distinct from other passwords you use, particularly across different accounts and platforms."
  //         title5="Randomness:"
  //         info5="Passwords should typically include a combination of different character types, such as uppercase letters, lowercase letters, numbers, and special characters (e.g., !@#$%&^*) rather than just birthday dates and pet names."
  //         title6="Personal Information:"
  //         info6="Passwords should avoid personal information, they should not include personal details such as your name, birthdate, or any easily discoverable information about you."
  //       />
  //     );
  //   } else if (item.pageTemplate == "TwoItems") {
  //     return (
  //       <TwoItems
  //         title="Multi-factor authentication (MFA)"
  //         item1="Multi-factor authentication (MFA), also known as two-factor authentication (2FA) or multi-step verification, is a security mechanism that requires users to provide two or more different factors to verify their identity when accessing a system, application, or online service."
  //         item2="Implementing multi-factor authentication can greatly enhance security and protect against various types of attacks, including password theft, phishing, and brute-force attacks. Many online services, banking institutions, and organizations offer MFA options to their users, through SMS verification codes, biometric technologies which is protection beyond username and password combination."
  //       />
  //     );
  //   } else if (item.pageTemplate == "HeaderPage") {
  //     return <HeaderPage />;
  //   }
  // };

  // const getLink = (pageNumber) => {
  //   var pageLink = "/lesson/page" + pageNumber;
  //   // alert(pageLink);
  //   return pageLink;
  // };

  // const getRoutes = () => {
  //   // allRoutes.map((route) => {
  //   //   if (route.collapse) {
  //   //     return getRoutes(route.collapse);
  //   //   }
  //   //   if (route.route) {
  //   //     return <Route exact path={route.route} element={route.component} key={route.key} />;
  //   //   }
  //   //   return null;
  //   // });
  //   // var id = pageData[1].pageTemplate(pageData[0]);
  //   // id = id + " "; alert(JSON.stringify(pageData[1].pageTemplate));
  //   // return (
  //   //   <Route
  //   //     path={getLink(pageData[1].pageNumber)}
  //   //     key={1}
  //   //     element={getPage(pageData[1].pageTemplate)}
  //   //   />
  //   // );
  //   // pageData.map((item, index) => {
  //   //   return (
  //   //     <Route path={getLink(item.pageNumber)} key={index} element={getPage(pageData[index])} />
  //   //   );
  //   // });
  // };

  // alert(JSON.stringify(getRoutes(routes)));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {/* {getRoutes(routes)} */}
        {/* {pageData.map((item, index) => (
          <Route path={getLink(item.pageNumber)} key={index} element={getPage(pageData[index])} />
        ))} */}

        <Route path="/lesson" element={<HeaderPage />} />
        {/* <Route path={getLink(1)} key={1} element={getPage(pageData[1])} /> */}
        {/* {
          <Route
            path={getLink("1")}
            key={1}
            element={getP(pageData[1].pageTemplate)}
          />
        } */}
        <Route path="/start" element={<HeaderPage />} />
        <Route
          path="/complete"
          element={
            <HeaderPage
              head1=""
              head2=""
              title="Start Quiz"
              nextpage="/quiz"
              button="Begin Course Quiz"
              description="You have finished the Course Materials, please continue to the quiz portion of the training to complete the course."
            />
          }
        />
        <Route path="/lesson/:id" element={<LessonPage />} />
        <Route path="/quiz/:id" element={<QuizPage />} />
        {/* <Route path="/lesson/page1" element={getPage()} /> */}
        <Route
          path="/lesson/page2"
          element={
            <FaqList
              title1="Complexity:"
              info1="Passwords should include a combination of uppercase and lowercase letters, numbers, and special symbols or punctuation marks."
              title2="Length:"
              info2="Ideally, a password should be at least 12 characters long. However, opting for an even longer password is preferable."
              title3="Unpredictability:"
              info3="Password should avoid easily guessable patterns, such as sequential numbers or common phrases."
              title4="Uniqueness:"
              info4="A password should be distinct from other passwords you use, particularly across different accounts and platforms."
              title5="Randomness:"
              info5="Passwords should typically include a combination of different character types, such as uppercase letters, lowercase letters, numbers, and special characters (e.g., !@#$%&^*) rather than just birthday dates and pet names."
              title6="Personal Information:"
              info6="Passwords should avoid personal information, they should not include personal details such as your name, birthdate, or any easily discoverable information about you."
            />
          }
        />
        <Route
          path="/lesson/page3"
          element={
            <TwoItems
              title="Multi-factor authentication (MFA)"
              // description={pageData.pageInfo}
              item1="Multi-factor authentication (MFA), also known as two-factor authentication (2FA) or multi-step verification, is a security mechanism that requires users to provide two or more different factors to verify their identity when accessing a system, application, or online service."
              item2="Implementing multi-factor authentication can greatly enhance security and protect against various types of attacks, including password theft, phishing, and brute-force attacks. Many online services, banking institutions, and organizations offer MFA options to their users, through SMS verification codes, biometric technologies which is protection beyond username and password combination."
              // item3={pageData.item3}
              // title1={pageData.title1}
              // title2={pageData.title2}
              // title3={pageData.title3}
            />
          }
        />
        <Route
          path="/lesson/page4"
          element={
            <ThreeItemsVertical
              title="The three common factors used in multi-factor authentication are:"
              description=""
              item1="Something you know: This factor requires knowledge that only the user should possess, such as a password, PIN, or answers to security questions."
              item2="Something you have: This factor involves a physical object that the user possesses, such as a smartphone, a hardware token, a smart card, or a USB security key. This object generates a one-time password (OTP) or acts as a second authentication factor."
              item3="Something you are: This factor relies on unique physical or behavioral characteristics of the user, often referred to as biometrics. Biometric factors can include fingerprints, facial recognition, voice recognition, or iris scans."
            />
          }
        />
        <Route path="/lesson/page5" element={<TwoRowInfo head1="testing" />} />
        <Route path="*" element={<Navigate to="/start" />} />
      </Routes>
    </ThemeProvider>
  );
}
