/* eslint-disable no-unused-vars */
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";
// import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/isonga/green_cyber.png";

function HeaderPage({ head1, head2, title, description, button, nextpage, background }) {
  return (
    <MKBox component="header" position="relative" height="800px">
      <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
        <Container>
          <Grid container flexDirection="row" alignItems="center">
            <MKTypography color="white" fontWeight="regular" py={0.8125} mr={2}>
              {head1}
            </MKTypography>
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <MKBox component="li"></MKBox>
              <MKBox component="li"></MKBox>
            </MKBox>
            <MKTypography color="white" fontWeight="regular" py={0.8125} mr={2}>
              {head2}
            </MKTypography>
            {/* <MKButton color="default">Begin Training</MKButton> */}
          </Grid>
        </Container>
      </MKBox>
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100%"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.4),
              rgba(gradients.dark.state, 0.4)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mx="auto"
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mb={3}
            >
              {title}
            </MKTypography>
            <MKTypography variant="h5" color="white" mt={1} mb={{ xs: 3, sm: 8 }} px={3}>
              {description}
            </MKTypography>
            <MKButton color="default" href={nextpage}>
              {button}
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

// Setting default props for the ExampleCard
HeaderPage.defaultProps = {
  head1: "Cyber Security Training Course Name",
  head2: "[Pages: ? - Duration: ?? min]",
  title: "Cyber Security Training Course Name",
  description:
    "Page Description goes here. Kila siku ninahitaji kula chakula vitamu. Nataka matunde na mboga na nyama mingi sana.",
  button: "Start Training Course",
  nextpage: "/lesson/1",
  background: "",
};

// Typechecking props for the ExampleCard
HeaderPage.propTypes = {
  head1: PropTypes.string,
  head2: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.string,
  nextpage: PropTypes.string,
  background: PropTypes.string,
};

export default HeaderPage;
